import React, { useState } from 'react';
import cn from 'classnames';
import { Information } from '../types/news';
import '../styles/components/News.scss';

type Props = {
  news: Information;
};

const NewsItem = (props: Props): React.ReactElement => {
  const [toggle, setToggle] = useState<boolean>(false);
  return (
    <div className="news-list__item--box">
      <div className={cn('item-title', toggle ? 'title-open' : 'title-close')}>
        <h3 className="title">{props.news.title.rendered}</h3>
        <span
          className={cn('icon', toggle ? 'icon-open' : 'icon-close')}
          onClick={() => setToggle(!toggle)}
        ></span>
      </div>

      <div className={cn('text-box', toggle ? 'text-box_open' : 'text-box_close')}>
        <p className="text" dangerouslySetInnerHTML={{ __html: props.news.acf.text }}></p>
      </div>
    </div>
  );
};

export default NewsItem;
