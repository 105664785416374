import React, { ReactElement, useState, useRef } from 'react';
import Slider from 'react-slick';
import { Case } from '../types/case';
import '../styles/components/CasesSlider.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

type Props = {
  slideItem: Case;
};

function CasesSlider(props: Props): ReactElement {
  const sliderRef = useRef<Slider>(null);
  const barRef = useRef<HTMLSpanElement>(null);
  const [slideCount, setSlideCount] = useState<number>(0);
  const baseWidth = 768;
  const isSp = window.outerWidth < baseWidth;
  const settings = {
    arrow: false,
    dots: false,
    infinite: false,
    speed: 400,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true,
    swipe: true,
    beforeChange: (current: number, next: number) => {
      setSlideCount(next);
    },
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          swipe: true,
        },
      },
    ],
  };

  return (
    <div className="cases-slider">
      <Slider {...settings} ref={sliderRef}>
        {props.slideItem.acf.images.map((image, index) => {
          return (
            <div key={index} className="slide-image">
              {index === 0 ? (
                <a href={props.slideItem.acf.url} target="_blank" rel="noreferrer">
                  <img src={image['image'].url} alt="" />
                </a>
              ) : (
                <img src={image['image'].url} alt="" />
              )}
            </div>
          );
        })}
      </Slider>
      <div className="control">
        <div className="btn-field">
          <div
            className="btn preview btn-hover"
            onClick={() => sliderRef.current?.slickPrev()}
          ></div>
          <div className="btn next" onClick={() => sliderRef.current?.slickNext()}></div>
        </div>
        <div className="bar-area">
          <div className="horizontal-bar"></div>
          <span
            ref={barRef}
            className="move-bar"
            style={{
              width: isSp
                ? `${100 / props.slideItem.acf.images.length}%`
                : `${100 / (props.slideItem.acf.images.length - 1)}%`,
              transform: `translateX(${
                barRef.current && barRef.current?.offsetWidth * slideCount
              }px)`,
            }}
          ></span>
        </div>
      </div>
    </div>
  );
}

export default CasesSlider;
