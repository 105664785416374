import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import '../styles/components/Header.scss';

type Props = {
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
};

const Header = (props: Props): React.ReactElement => {
  const [open, setOpen] = useState(false);
  const [logoBlack, setLogoBlack] = useState<boolean>(true);
  const baseWidth = 768;
  const isSp = window.outerWidth < baseWidth;
  const linkItems = [
    {
      name: 'ABOUT',
      number: 2,
      activeArea: [2],
    },
    {
      name: 'PARTNERS',
      number: isSp ? 4 : 3,
      activeArea: [3, 4],
    },
    {
      name: 'TEAM',
      number: isSp ? 6 : 5,
      activeArea: [5, 6, 7, 8],
    },
    {
      name: 'CASES',
      number: isSp ? 8 : 9,
      activeArea: [9, 10],
    },
    {
      name: 'PORTFOLIO',
      number: isSp ? 10 : 11,
      activeArea: [11, 12],
    },
    {
      name: 'NEWS',
      number: isSp ? 12 : 13,
      activeArea: [13],
    },
    {
      name: 'CONTACT',
      number: isSp ? 13 : 14,
      activeArea: [14],
    },
    {
      name: 'COMPANY',
      number: isSp ? 14 : 15,
      activeArea: [15],
    },
  ];

  useEffect(() => {
    logoColor();
  }, [props.index]);

  useEffect(() => {
    if (open) setLogoBlack(true);
    else logoColor();
  }, [open]);

  const logoColor = () => {
    if (isSp) {
      const black = [0, 1, 2, 3, 6, 7, 12, 14];
      const result = black.includes(props.index);
      setLogoBlack(result);
      setOpen(false);
    } else {
      const black = [0, 1, 2, 5, 6, 7, 8, 13, 15];
      const result = black.includes(props.index);
      setLogoBlack(result);
    }
  };

  return (
    <header className="l-header">
      <div className={cn('l-header__container', open ? 'is-open' : null)}>
        {logoBlack ? (
          <div className="l-header__logo" onClick={() => props.setIndex(0)}>
            <img className="img" src={`${IMAGE_PATH}images/icon-logo_black.svg`} alt="combo" />
          </div>
        ) : (
          <div
            className="l-header__logo"
            style={{ cursor: 'pointer' }}
            onClick={() => props.setIndex(0)}
          >
            <img className="img" src={`${IMAGE_PATH}images/icon-logo_white.svg`} alt="combo" />
          </div>
        )}
        <nav className="l-header__nav">
          <ul className="page-link">
            {linkItems.map((item, index) => {
              const active = item.activeArea.includes(props.index);
              return (
                <li
                  className="page-link__item"
                  key={index}
                  onClick={() => props.setIndex(item.number)}
                >
                  {item.name}
                  {!isSp && active && <p className="active-bar"></p>}
                </li>
              );
            })}
          </ul>

          <ul className="option-link">
            <a
              href={`${IMAGE_PATH}images/combo_about.pdf`}
              target="_blank"
              rel="noreferrer"
              download="combo_about.pdf"
            >
              <li className="option-link__item btn-hover">資料ダウンロード</li>
            </a>

            <li
              className="option-link__item btn-hover"
              onClick={() => props.setIndex(isSp ? 13 : 14)}
            >
              CONTACT
            </li>
          </ul>
        </nav>

        <button
          type="button"
          className={cn('l-sm', 'btn-hmb', !logoBlack && 'is-white')}
          onClick={() => setOpen(!open)}
        >
          <span className={cn('btn-hmb__line', !logoBlack && 'is-white__line')}></span>
        </button>
      </div>
    </header>
  );
};

export default Header;
