import { useEffect, useState } from 'react';
import { postContact, Param } from '../utils/api';

type Res = {
  into: string;
  message: string;
  posted_data_hash: string;
  status: string;
};

export const useSendContact = (): [(param: Param) => void, Res | null] => {
  const [param, setParam] = useState<Param>();
  const [res, setRes] = useState<Res | null>(null);

  useEffect(() => {
    if (!param) return;
    (async () => {
      try {
        const res = await postContact(param);
        setRes(res.data as Res);
      } catch (error) {
        console.error(error);
        setRes(null);
      }
    })();
  }, [param]);

  return [setParam, res];
};
