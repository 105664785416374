import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const config = (): AxiosRequestConfig => {
  const headers: any = {
    ['content-type']: 'application/json',
  };
  // if (window.NONCE && window.NONCE.indexOf('<?php') < 0) {
  //   headers['X-WP-Nonce'] = window.NONCE;
  // }
  return {
    headers: headers,
  };
};

export const fetch = (endpoint: string) =>
  axios.get(process.env.REACT_APP_API_URL + endpoint, {
    ...config(),
  });

//ゲストユーザーまたはシングルページの読み込み
// if (!window.NONCE || forSinglePage || window.NONCE.indexOf('<?php') >= 0) {
//   return axios.get(process.env.REACT_APP_API_URL + endpoint, config());
// }

export type Param = {
  type: string;
  yourname: string;
  email: string;
  content: string;
};

export const postContact = (params: Param) => {
  const id = 49;
  const data = new FormData();
  data.append('type', params.type);
  data.append('yourname', params.yourname);
  data.append('email', params.email);
  data.append('content', params.content);

  return axios.post(`/wp-json/contact-form-7/v1/contact-forms/${id}/feedback`, data, {
    ...config(),
  });
};
