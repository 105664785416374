import React, { useEffect, useState, useRef } from 'react';
import Cases from '../components/Cases';
import '../styles/common/reset.css';
import '../styles/common/layout.scss';
import Header from '../components/Header';
import PageTitle from '../components/PageTitle';
import FirstView from '../components/FirstView';
import Business from '../components/Business';
import LogoList from '../components/LogoList';
import Team from '../components/Team';
import News from '../components/News';
import Contact from '../components/Contact';
import CompanyProfile from '../components/CompanyProfile';
import Footer from '../components/Footer';
import PictureStory from '../components/common/PictureStory';
import Background from '../components/common/Background';
import cn from 'classnames';
import { useApi } from '../hooks/useApi';
import { useSendContact } from '../hooks/useSendContact';
import { useWindowHeight } from '../hooks/useWindowHeight';
import { team } from '../data/team';
import { partners } from '../data/partners';

type TLogos = {
  urlSite: string;
  urlImg: string;
  alt: string;
};

type TContactData = {
  type: string;
  yourname: string;
  email: string;
  content: string;
};

function Top(): React.ReactElement {
  const [index, setIndex] = useState<number>(0);
  const [cases, portfolio, news] = useApi();
  const [footerHight, setFooterHight] = useState<number>(0);
  const [footerTopHight, setFooterTopHight] = useState<number>(0);
  const [sendContactForm, contactFormResponse] = useSendContact();
  const footerRef = useRef<HTMLDivElement>(null);
  const footerTopRef = useRef<HTMLDivElement>(null);

  const baseWidth = 768;
  const isSp = window.outerWidth < baseWidth;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [height] = useWindowHeight();

  useEffect(() => {
    if (footerRef.current) {
      const footerHight = footerRef.current.clientHeight;
      setFooterHight(footerHight);
    }
    if (footerTopRef.current) {
      const footerTopHight = footerTopRef.current.clientHeight;
      setFooterTopHight(footerTopHight);
    }
  }, []);

  useEffect(() => {
    if (contactFormResponse !== null) {
      console.log('送信成功', contactFormResponse);
    }
  }, [contactFormResponse]);

  const contactOnSend = (data: TContactData) => {
    sendContactForm(data);
  };

  useEffect(() => {
    const el = wrapperRef.current?.children[2].children[index].children[0];
    if (!el) return;
    el.scrollIntoView({
      block: 'start',
    });
  }, [index]);

  return (
    <div className="Top">
      <div className="wrapper" ref={wrapperRef}>
        <Header index={index} setIndex={setIndex} />
        <Background index={index} />
        <PictureStory
          index={index}
          onChange={(ind) => {
            setIndex(ind);
          }}
        >
          <section className={cn('initial', index !== 0 && 'clear')}>
            <FirstView index={index} />
          </section>
          <section>
            <FirstView textMode={true} index={index} />
          </section>
          {isSp && (
            <section>
              <Business spMode={isSp} hiddenIndex={1} setIndex={setIndex} />
            </section>
          )}
          {isSp && (
            <section>
              <Business spMode={isSp} hiddenIndex={0} setIndex={setIndex} />
            </section>
          )}

          {!isSp && (
            <section>
              <Business spMode={isSp} setIndex={setIndex} />
            </section>
          )}
          <section>
            <PageTitle theme={'blue'}>
              <div className="title-box__contents partners">
                <h2 className="title partners">PARTNERS</h2>
              </div>
            </PageTitle>
          </section>
          <section>
            <LogoList title={'PARTNERS'} theme={'partners'} partners={partners} />
          </section>
          <section>
            <PageTitle theme={'gray'}>
              <div className="title-box__contents team">
                <h2 className="title team">TEAM</h2>
              </div>
            </PageTitle>
          </section>

          {isSp && (
            <section>
              <section className="item-team">
                <div className="l-container" style={{ minHeight: `${height}px` }}>
                  <div className={cn('team-list', 'teamA')}>
                    <Team theme={'right'} item={team[0]} />
                    <Team theme={'left'} item={team[1]} />
                  </div>

                  <div className={cn('team-list', 'teamB')}>
                    <Team theme={'left'} item={team[2]} />
                    <Team theme={'right'} item={team[3]} />
                  </div>

                  <div className={cn('team-list', 'teamA')}>
                    <Team theme={'right'} item={team[4]} />
                  </div>
                </div>
              </section>
            </section>
          )}

          {!isSp && (
            <section>
              <section className="item-team" style={{ height: `${height}px` }}>
                <div className="l-container">
                  <div className={cn('team-list', 'teamA')}>
                    <Team theme={'right'} item={team[0]} />
                    <Team theme={'left'} item={team[1]} />
                  </div>
                </div>
              </section>
            </section>
          )}
          {!isSp && (
            <section>
              <section className="item-team" style={{ height: `${height}px` }}>
                <div className="l-container">
                  <div className={cn('team-list', 'teamB')}>
                    <Team theme={'left'} item={team[2]} />
                    <Team theme={'right'} item={team[3]} />
                  </div>
                </div>
              </section>
            </section>
          )}

          {!isSp && (
            <section>
              <section className="item-team" style={{ height: `${height}px` }}>
                <div className="l-container">
                  <div className={cn('team-list', 'teamC')}>
                    <Team theme={'right'} item={team[4]} />
                  </div>
                </div>
              </section>
            </section>
          )}

          <section>
            <PageTitle theme={'blue'}>
              <div className="title-box__contents case">
                <h2 className="title">CASES</h2>
              </div>
            </PageTitle>
          </section>
          <section>
            <Cases cases={cases} />
          </section>
          <section>
            <PageTitle theme={'blue'}>
              <div className="title-box__contents">
                <h2 className="title portfolio">PORTFOLIO</h2>
              </div>
            </PageTitle>
          </section>
          <section>
            {portfolio && (
              <LogoList title={'PORTFOLIO'} theme={'portfolio'} portfolio={portfolio} />
            )}
          </section>
          <section>
            <News news={news} />
          </section>
          <section>
            <Contact onSend={(data: TContactData) => contactOnSend(data)} />
          </section>
          <section>
            <div
              style={{
                minHeight: `${height}px`,
              }}
            >
              <div ref={footerTopRef}>
                <CompanyProfile />
              </div>
              <div ref={footerRef}>
                <Footer setIndex={setIndex} />
              </div>
            </div>
          </section>
        </PictureStory>
      </div>
    </div>
  );
}

export default Top;
