import { useState, useEffect } from 'react';

export const useWindowHeight = () => {
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    setHeight(window.innerHeight);
  }, []);

  const resizeHeight = () => {
    setHeight(window.innerHeight);
  };
  window.addEventListener('resize', resizeHeight);

  return [height];
};
