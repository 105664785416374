import React, { useState, useEffect, useRef } from 'react';
import ContactItem from './ContactItem';
import cn from 'classnames';
import { useForm, FormProvider } from 'react-hook-form';
import { useWindowHeight } from '../hooks/useWindowHeight';
import '../styles/components/Contact.scss';

type TContactData = {
  type: string;
  yourname: string;
  email: string;
  content: string;
};

type Props = {
  onSend: (data: TContactData) => void;
};

function Contact(props: Props): React.ReactElement {
  const contents = [
    {
      title: '事業を立ち上げたい方',
      text: '自分のアイデアで新しい事業を立ち上げたいが、進め方や実装に悩む方。現在働いていてもOKです。',
    },
    {
      title: '経営者人材',
      text: 'CxOなど経営経験があり、心が踊る新しい事業に出会いたい方。現在働いていてもOKです。',
    },
    {
      title: '企業内の新規事業',
      text: '考案中またはプロジェクト開始した、企業内の新規事業案件のコンサルティングと実装パートナーを探している方。UI/UXから実装まで、経験値の高いチームで共創します。',
    },
    {
      title: 'スタートアップ',
      text: 'アーリー〜ミドル期の、主にマーケティング・UI/UX・PRに課題感を持つスタートアップ事業をブーストします。',
    },
  ];

  const defaultValue = {
    type: '0',
    yourname: '',
    email: '',
    content: '',
  };
  const [nullValidate, setNullValidate] = useState<boolean>(false);
  const [mailValidate, setMailValidate] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<TContactData>(defaultValue);
  const formRef = useRef<HTMLDivElement>(null);
  const [height] = useWindowHeight();

  const methods = useForm<TContactData>({
    defaultValues: formValue,
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (!formValue?.yourname.trim()) setNullValidate(true);
    else setNullValidate(false);
    if (!formValue?.email.trim()) setNullValidate(true);
    else setNullValidate(false);
    if (!formValue?.content.trim()) setNullValidate(true);
    else setNullValidate(false);

    const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/;
    if (reg.test(formValue?.email)) {
      setMailValidate(false);
    } else {
      setMailValidate(true);
    }
  }, [watch('type'), watch('yourname'), watch('email'), watch('content')]);

  useEffect(() => {
    const value: TContactData = {
      type: watch('type'),
      yourname: watch('yourname'),
      email: watch('email'),
      content: watch('content'),
    };
    setFormValue(value);
  }, [watch('type'), watch('yourname'), watch('email'), watch('content')]);

  const send = () => {
    if (nullValidate || mailValidate) {
      console.log('失敗');
    }
    if (nullValidate || mailValidate) return;
    props.onSend(formValue);
    setFormValue(defaultValue);
    reset(formValue);
  };

  const itemClick = (index: number) => {
    formRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    const value = formValue;
    value.type = index.toString();
    setFormValue(value);
    methods.setValue('type', value.type);
  };

  return (
    <section className="contact" style={{ minHeight: `${height}px` }}>
      <div className="contact-inner">
        <div className="title">
          <h2 className="contents-title">CONTACT</h2>
        </div>
        <div className="contents-area">
          {contents &&
            contents.map((item, index) => {
              return (
                <div key={index} className="list">
                  <ContactItem item={item} num={index} onClick={() => itemClick(index)} />
                </div>
              );
            })}
        </div>
        <div className="form-area" id="form" ref={formRef}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit((data) => console.log(data))}>
              <div className="input-area">
                <div className="category">
                  <p>お問い合わせ種別</p>
                  <span className="batch">必須</span>
                </div>
                <div className="select">
                  <select className="input" {...register('type', { required: true })}>
                    {contents.map((item, index) => {
                      return (
                        <option value={index.toString()} key={index}>
                          {index + 1}.{item.title}
                        </option>
                      );
                    })}
                     <option value={'4'}>
                          5.その他
                        </option>
                  </select>
                  <div className="icon"></div>
                </div>
              </div>
              <div className="input-area">
                <div className={cn('category', `category-text`)}>
                  <p>お名前</p>
                  <span className="batch">必須</span>
                </div>
                <div>
                  <input
                    {...register('yourname', { required: true })}
                    type="text"
                    className={cn('input', 'text', errors.yourname && 'has-error')}
                  />
                  {errors.yourname && <p className="error">※お名前をご入力ください</p>}
                </div>
              </div>
              <div className="input-area">
                <div className={cn('category', `category-text`)}>
                  <p>メールアドレス</p>
                  <span className="batch">必須</span>
                </div>
                <div>
                  <input
                    {...register('email', {
                      required: true,
                      pattern: {
                        value:
                          /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/,
                        message: 'メールアドレスの形式が不正です',
                      },
                    })}
                    type="text"
                    className={cn('input', 'text', errors.email && 'has-error')}
                  />
                  {errors.email?.message && <p className="error">※{errors.email?.message}</p>}
                  {!errors.email?.message && errors.email ? (
                    <p className="error">※メールアドレスをご入力ください</p>
                  ) : null}
                </div>
              </div>
              <div className="input-area">
                <div className={cn('category', `category-text`)}>
                  <p>お問い合わせ内容</p>
                  <span className="batch">必須</span>
                </div>
                <div>
                  <textarea
                    {...register('content', { required: true })}
                    className={cn('input', 'text-area', errors.content && 'has-error')}
                  />
                  {errors.content && <p className="error">※お問い合わせ内容をご入力ください</p>}
                </div>
              </div>
              <button type="submit" className="send-btn btn-hover" onClick={send}>
                送 信 す る
              </button>
            </form>
          </FormProvider>
        </div>
      </div>
    </section>
  );
}

export default Contact;
