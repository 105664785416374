import React from 'react';
import '../styles/components/CompanyProfile.scss';

function CompanyProfile(): React.ReactElement {
  return (
    <section className="company-profile">
      <div className="company-profile_inner">
        <div className="title">
          <h2>COMPANY PROFILE</h2>
        </div>
        <div className="contents">
          <div className="list">
            <div className="list-title">会社名</div>
            <div className="list-text">株式会社コンボ</div>
          </div>
          <div className="list">
            <div className="list-title">取締役</div>
            <div className="list-text">
              <table className="list-table">
                <tr>
                  <td>代表取締役</td>
                  <td>中村 洋基</td>
                </tr>
                <tr>
                  <td>取締役</td>
                  <td>田中 潤</td>
                </tr>
                <tr>
                  <td>社外取締役</td>
                  <td>菅野 清太郎</td>
                </tr>
                <tr>
                  <td>社外監査役</td>
                  <td>嶽野 稔之</td>
                </tr>
              </table>
            </div>
          </div>
          <div className="list">
            <div className="list-title">資本金</div>
            <div className="list-text">160,000,000円（資本準備金含む）</div>
          </div>
          <div className="list">
            <div className="list-title">主要株主</div>
            <div className="list-text">
              株式会社パーティー
              <br />
              凸版印刷株式会社
              <br />
              株式会社ギークピクチュアズ
              <br />
              株式会社ベクトル
            </div>
          </div>
          <div className="list">
            <div className="list-title">住所</div>
            <div className="list-text">
              〒150-0033
              <br />
              東京都渋谷区猿楽町17-10
              <br />
              代官山アートビレッジ3階
            </div>
          </div>
          <div className="list">
            <div className="list-title">事業内容</div>
            <div className="list-text">
              新規事業を創出し、成長させる
              <br />
              <br />
              ①スタートアップスタジオ事業
              <br />
              「アイデアはあるが実装方法に悩む経営者」や「社内で新規事業プロジェクトは立ち上がったが進め方に悩んでいる企業」などの課題に応えます。
              <br />
              <br />
              ②アクセラレーション事業
              <br />
              スタートアップに不足しがちな「マーケティング」「UI/UX」「PR」「ブランディング」を強化し、出資・ハンズオン支援を行います。
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CompanyProfile;
