import { useEffect, useState } from 'react';
import { fetch } from '../utils/api';
import { Case } from '../types/case';
import { Portfolio } from '../types/portfolio';
import { Information } from '../types/news';

export const useApi = (): [Case[], Portfolio[], Information[]] => {
  const [cases, setCases] = useState<Case[]>([]);
  const [portfolio, setPortfolio] = useState<Portfolio[]>([]);
  const [news, setNews] = useState<Information[]>([]);
  useEffect(() => {
    const parPage = 100;
    const fetchData = async () => {
      try {
        const cases = await fetch('/cases');
        setCases(cases.data);
        const portfolio = await fetch(`/portfolio?per_page=${parPage}`);
        setPortfolio(portfolio.data);
        const news = await fetch('/news');
        setNews(news.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  return [cases, portfolio, news];
};
