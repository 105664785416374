import React from 'react';
import { useWindowHeight } from '../hooks/useWindowHeight';
import '../styles/components/business.scss';

type Props = {
  spMode?: boolean;
  hiddenIndex?: 0 | 1;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
};

const businessContent = (props: Props): React.ReactElement => {
  const baseWidth = 768;
  const isSp = window.outerWidth < baseWidth;
  const [height] = useWindowHeight();
  return (
    <section
      className="item-business"
      style={{
        height: isSp ? `${height}px` : undefined,
        minHeight: !isSp ? `${height}px` : undefined,
      }}
    >
      <div className="l-container">
        <ul className="business-list">
          {props.hiddenIndex !== 0 && (
            <li className="business-list__item">
              {/* タイトル・テキスト */}
              <div className="business-list__item--text">
                <div className="title-block">
                  <h3 className="title">
                    <span className="l-lg">スタートアップスタジオ事業</span>
                    <span className="l-sm">スタートアップ</span>
                    <span className="l-sm">スタジオ事業</span>
                  </h3>
                </div>

                <div className="text-box">
                  <p className="text">
                    0→1に必要な事業アイデアと、
                    <br />
                    実装、PRで新しい事業を
                    <br className="l-sm" />
                    連続的に生み出していきます。
                    <br />
                    御社の新規事業のプロジェクトの
                    <br />
                    実装パートナーとしてもご利用いただけます。
                  </p>
                </div>
              </div>

              {/* 画像 */}
              <div className="business-list__item--img">
                <div className="img-box">
                  <picture className="img-box__img">
                    <img
                      className="img l-lg"
                      src={`${IMAGE_PATH}images/business/img-business01.png`}
                      alt="スタートアップ事業"
                    />
                    <img
                      className="img l-sm"
                      src={`${IMAGE_PATH}images/business/img-business01_sm.png`}
                      alt="スタートアップ事業"
                    />
                  </picture>
                </div>
              </div>
            </li>
          )}
          {props.hiddenIndex !== 1 && (
            <li className="business-list__item">
              {/* タイトル・テキスト */}
              <div className="business-list__item--text">
                <div className="title-block">
                  <h3 className="title">
                    <span className="l-lg">アクセラレーション事業</span>
                    <span className="l-sm">アクセラレーション</span>
                    <span className="l-sm">事業</span>
                  </h3>
                </div>

                <div className="text-box">
                  <p className="text">
                    起業したスタートアップに不足しがちな「マーケティング」「UI/UX」「PR」「ブランディング」を強化し、出資・ハンズオン支援します。PARTYをはじめとする
                    <span className="text-link" onClick={() => props.setIndex(isSp ? 4 : 3)}>
                      パートナー企業
                    </span>
                    のスペシャリスト、大企業との連携交渉支援など、ご活用いただけます。
                  </p>
                </div>
              </div>

              {/* 画像 */}
              <div className="business-list__item--img second">
                <div className="img-box">
                  <picture className="img-box__img">
                    <img
                      className="img"
                      src={`${IMAGE_PATH}images/business/img-business02.png`}
                      alt="スタートアップ事業"
                    />
                  </picture>
                </div>
              </div>
            </li>
          )}
        </ul>
      </div>
    </section>
  );
};

export default businessContent;
