import React, { ReactElement } from 'react';
import CasesSlider from './CasesSlider';
import { Case } from '../types/case';
import { useWindowHeight } from '../hooks/useWindowHeight';
import '../styles/components/Cases.scss';

type Props = {
  cases: Case[];
};

function Cases(props: Props): ReactElement {
  const [height] = useWindowHeight();
  return (
    <section className="cases">
      <div className="cases-inner" style={{ minHeight: `${height}px` }}>
        <h2 className="title">CASES</h2>
        <div className="slide-area">
          {props.cases.map((slider, index) => {
            return (
              <div className="slider" key={index}>
                {slider.acf.images && <CasesSlider slideItem={slider} />}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Cases;
