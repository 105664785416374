import React, { VFC } from 'react';
import '../../styles/common/Background.scss';
import cn from 'classnames';

import * as THREE from 'three';
import { MarchingCubes } from 'three/examples/jsm/objects/MarchingCubes';
import { useEffect } from 'react';

let container;
let camera: THREE.PerspectiveCamera, scene: THREE.Scene, renderer: THREE.WebGLRenderer;
let light: THREE.DirectionalLight, ambientLight: THREE.AmbientLight;
let effect: any, resolution: number;
const lightColor = new THREE.Color(0xcfe2e6);
const darkColor = new THREE.Color(0x00032f);
const baseWidth = 768;
const isSp = window.outerWidth < baseWidth;

const effectController = {
  material: 'dotted',

  speed: 0.05,
  numBlobs: 15,
  resolution: isSp ? 25 : 35,
  isolation: 100,

  hue: 190 / 360,
  saturation: 0.1,
  lightness: 0.9,

  lx: 0.5,
  ly: 0.5,
  lz: 0.5,

  lhue: 0.04,
  lsaturation: 1.0,
  llightness: 0.5,
};

let time = 0;
const clock = new THREE.Clock();

function init() {
  container = document.getElementById('renderer');

  // CAMERA
  camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 10000);
  camera.position.set(-250, 250, 750);

  // SCENE
  scene = new THREE.Scene();

  // LIGHTS
  light = new THREE.DirectionalLight(0xcfe2e6, 0.3);
  light.position.set(0.5, 0.5, 1);
  scene.add(light);

  ambientLight = new THREE.AmbientLight(0xcfe2e6, 0.9);
  scene.add(ambientLight);

  // MARCHING CUBES

  // MATERIALS
  const mat = new THREE.MeshStandardMaterial({ color: new THREE.Color(0x000000) });
  effect = new MarchingCubes(effectController.resolution, mat, true, true);
  effect.position.set(0, 0, 0);
  effect.scale.set(700, 700, 700);

  effect.enableUvs = false;
  effect.enableColors = false;

  scene.add(effect);

  // RENDERER

  renderer = new THREE.WebGLRenderer({ alpha: true });
  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.setClearColor(0x000000, 0); // the default
  container?.appendChild(renderer.domElement);

  // EVENTS

  window.addEventListener('resize', onWindowResize);
}

//

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize(window.innerWidth, window.innerHeight);
}

// this controls content of marching cubes voxel field
function updateCubes(object: any, time: any, numblobs: number) {
  object.reset();

  // fill the field with some metaballs
  const subtract = 12;
  const strength = 1.2 / ((Math.sqrt(numblobs) - 1) / 4 + 1);

  for (let i = 0; i < numblobs; i++) {
    const ballx = Math.sin(i + 1.26 * time * (1.03 + 0.5 * Math.cos(0.21 * i))) * 0.27 + 0.5;
    const bally = Math.abs(Math.cos(i + 1.12 * time * Math.cos(1.22 + 0.1424 * i))) * 0.77; // dip into the floor
    const ballz = Math.cos(i + 1.32 * time * 0.1 * Math.sin(0.92 + 0.53 * i)) * 0.27 + 0.5;

    object.addBall(ballx, bally, ballz, strength, subtract);
  }
}

export function animate() {
  requestAnimationFrame(animate);
  render();
}

function render() {
  const delta = clock.getDelta();

  time += delta * effectController.speed;

  // marching cubes
  if (effectController.resolution !== resolution) {
    resolution = effectController.resolution;
    effect.init(Math.floor(resolution));
  }

  if (effectController.isolation !== effect.isolation) {
    effect.isolation = effectController.isolation;
  }

  updateCubes(effect, time, effectController.numBlobs);

  // materials
  if (effect.material instanceof THREE.ShaderMaterial) {
    effect.material.uniforms['uBaseColor'].value.setHSL(
      effectController.hue,
      effectController.saturation,
      effectController.lightness
    );
  } else {
    effect.material.color.setHSL(
      effectController.hue,
      effectController.saturation,
      effectController.lightness
    );
  }

  // render
  renderer.render(scene, camera);
}

const transitionTo = (theme: string) => {
  if (theme === 'light') {
    light.copy(new THREE.DirectionalLight(lightColor, 0.3));
    ambientLight.copy(new THREE.AmbientLight(lightColor, 0.9));
  } else {
    light.copy(new THREE.DirectionalLight(darkColor, 0.2));
    ambientLight.copy(new THREE.AmbientLight(darkColor, 0.1));
  }
};

type Props = {
  index: number;
};

const Background: VFC<Props> = ({ index }) => {
  const getTheme = (): string => {
    if (isSp) {
      switch (index) {
        // case 3:
        //   return 'dark';
        case 4:
          return 'dark';
        case 5:
          return 'dark';
        // case 7:
        //   return 'dark';
        case 8:
          return 'dark';
        case 9:
          return 'dark';
        case 10:
          return 'dark';
        case 11:
          return 'dark';
        case 13:
          return 'dark';
        default:
          return 'light';
      }
    } else {
      switch (index) {
        case 3:
          return 'dark';
        case 4:
          return 'dark';
        case 9:
          return 'dark';
        case 10:
          return 'dark';
        case 11:
          return 'dark';
        case 12:
          return 'dark';
        case 14:
          return 'dark';
        default:
          return 'light';
      }
    }
  };

  useEffect(() => {
    init();
    animate();
  }, []);

  useEffect(() => {
    const theme = getTheme();
    transitionTo(theme);
  }, [index]);

  return (
    <section id="renderer" className={cn('background', `background-${getTheme()}`)}>
      <div>
        <div>
          <div></div>
        </div>
      </div>
    </section>
  );
};
export default Background;
