import React, { useState } from 'react';
import cn from 'classnames';
import '../styles/components/ContactItem.scss';

type Item = {
  title: string;
  text: string;
};

type Props = {
  num: number;
  item: Item;
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

function ContactItem(props: Props): React.ReactElement {
  const [toggle, setToggle] = useState<boolean>(false);

  return (
    <section className="contact-item">
      <div className="item">
        <div className="title-area">
          <div className="titles">
            <p className="item-num">
              <span>{props.num + 1}</span>
              <span>.</span>
            </p>
            <p className="item-title">{props.item.title}</p>
          </div>
          <div
            className={cn('toggle-btn', toggle ? 'toggle-btn_open' : 'toggle-btn_close')}
            onClick={() => setToggle(!toggle)}
          ></div>
        </div>
        <div className="text-area">
          <div
            className={cn(
              'text-area_inner',
              toggle ? 'text-area_inner_open' : 'text-area_inner_close'
            )}
          >
            <p>{props.item.text}</p>
            <span className="contact-btn btn-hover" onClick={props.onClick}>
              問い合わせる
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactItem;
