import React from 'react';
import ReactDOM from 'react-dom';
import Top from './pages/Top';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import store from './redux';
import consistant from './utils/consistant';

const Router = BrowserRouter;

// if (process.env.NODE_ENV === 'development') {
//   Router = HashRouter;
// }
console.info('APP VERSION', process.env.REACT_APP_COMMIT_HASH);

const Container = (props: any) => {
  return (
    <Route exact={props.exact} path={props.path}>
      {(routeProps) => (
        <CSSTransition
          in={routeProps.match != null}
          timeout={consistant.pageTransitionDuration}
          unmountOnExit
        >
          <div className={`container container-${props.name}`}>
            {React.cloneElement(props.children, { ...routeProps })}
          </div>
        </CSSTransition>
      )}
    </Route>
  );
};
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Container exact path="/" name="top">
          <Top />
        </Container>
        {/* for debug */}
        <Container exact path="/cms" name="top">
          <Top />
        </Container>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
