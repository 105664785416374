import React, { ReactElement } from 'react';
import { useWindowHeight } from '../hooks/useWindowHeight';
import cn from 'classnames';
import '../styles/components/PageTitle.scss';

type Props = {
  // title: string;
  // subTitle: string;
  theme: 'blue' | 'gray';
  children: ReactElement;
};

const PageTitle = (props: Props): React.ReactElement => {
  const [height] = useWindowHeight();
  return (
    <div className={cn('title-box', `title-box__${props.theme}`)} style={{ height: `${height}px` }}>
      <div>{props.children}</div>
    </div>
  );
};
export default PageTitle;
