import React from 'react';
import '../styles/components/Footer.scss';

type Props = {
  setIndex: React.Dispatch<React.SetStateAction<number>>;
};

function Footer(props: Props): React.ReactElement {
  const baseWidth = 768;
  const isSp = window.outerWidth < baseWidth;

  return (
    <section className="footer">
      <div className="footer-inner">
        <div className="btn-area">
          <a
            href={`${IMAGE_PATH}images/combo_about.pdf`}
            target="_blank"
            rel="noreferrer"
            download="combo_about.pdf"
          >
            <div className="btn">資料ダウンロード</div>
          </a>
          <div className="btn" onClick={() => props.setIndex(isSp ? 13 : 14)}>
            CONTACT
          </div>
        </div>
        <div className="footer-logo">
          <img src={`${IMAGE_PATH}images/icon-logo_white.svg`} alt="combo" />
        </div>
        <span className="copy">©combo inc. all rights reserved.</span>
      </div>
    </section>
  );
}

export default Footer;
