import React, { useState, useRef, useEffect } from 'react';
import '../../styles/common/PictureStory.scss';
import _ from 'underscore';
import { delay } from '../../utils/delay';
import SwipeListener from 'swipe-listener';

type Props = {
  children: React.ReactNode;
  onChange(index: number): void;
  index: number;
};

function PictureStory(props: Props) {
  const INTERVAL = 500;
  const THRESHOLD = 30;

  const [canDown, setCanDown] = useState<boolean>(false);
  const [canUp, setCanUp] = useState<boolean>(false);
  const canUpRef = useRef<boolean>(false);
  canUpRef.current = canUp;
  const canDownRef = useRef<boolean>(false);
  canDownRef.current = canDown;
  const indexRef = useRef<number>(0);
  indexRef.current = props.index;

  const [length, setLength] = useState<number>(0);
  const lengthRef = useRef<number>();
  const controller = new AbortController();

  const [children, setChildren] = useState<HTMLCollection>();
  const wrapperRef = useRef<HTMLDivElement>(null);
  let updatable = true;
  // TODO refからchildrenの要素数が取れない場合がある

  const baseWidth = 768;
  const isSp = window.outerWidth < baseWidth;

  const sections = isSp ? 15 : 16;

  useEffect(() => {
    const update = (e: any) => {
      const current = wrapperRef.current;
      const activeElement = Array.from(current?.children || [])[indexRef.current];
      const activeElementChild = activeElement?.children[0];

      const setValue = (index: number) => {
        props.onChange(index);
        //一度のページ送りでcanUp, canDownはリセット
        setCanUp(false);
        setCanDown(false);
      };

      if (
        e.deltaY > 0 &&
        (activeElement?.clientHeight === activeElementChild?.clientHeight || canDownRef.current)
      ) {
        //下方向への進行、および子コンテンツの高さがwindowと同じ、もしくはcanDown状態
        if (indexRef.current < sections - 1) setValue(indexRef.current + 1);
      } else if (
        e.deltaY < 0 &&
        (activeElement?.clientHeight === activeElementChild?.clientHeight || canUpRef.current)
      ) {
        //上方向への進行、および子コンテンツの高さがwindowと同じ、もしくはcanUp状態
        if (indexRef.current > 0) setValue(indexRef.current - 1);
      }
    };

    window.addEventListener('wheel', (e) => {
      if (Math.abs(e.deltaY) > THRESHOLD) {
        if (updatable) {
          (async () => {
            updatable = false;
            update(e);
            await delay(INTERVAL);
            updatable = true;
          })();
        }
      }
    });
    const el = document.querySelector('body') as HTMLElement;
    const listener = SwipeListener(el, { minVertical: 65 });

    el.addEventListener('swipe', (e: any) => {
      if (e.detail.directions.top === true) {
        update({ deltaY: 1 });
      } else if (e.detail.directions.bottom === true) {
        update({ deltaY: -1 });
      }
    });
  }, []);

  useEffect(() => {
    const current = wrapperRef.current;
    const collection = current?.children;
    setChildren(collection);
  }, [wrapperRef]);

  const checkElementScrollable = (child: HTMLDivElement) => {
    const grandChild = child?.children[0];
    const checkScrollPos = (e: any) => {
      //スクロール位置が上限、下限のそれぞれ5px以内にあるか
      const top = e.target.scrollTop <= 5;
      const bottom = grandChild?.clientHeight - e.target.scrollTop - child?.clientHeight <= 5;
      setCanUp(top);
      setCanDown(bottom);
    };

    if (child?.clientHeight !== grandChild?.clientHeight) {
      checkScrollPos({ target: { scrollTop: 0 } });

      child.addEventListener('scroll', checkScrollPos);
      child.addEventListener('mouseenter', (e: any) => {
        //スクロールが動作しないときのためのhack
        child.scroll(0, 1);
      });
    }
  };

  useEffect(() => {
    setLength(Array.from(children || []).length);
    // Array.from(children || []).forEach((child) => {
    // checkElementScrollable(child);
    // });
  }, [children]);

  useEffect(() => {
    setActive();
  }, [props.index]);

  const setActive = () => {
    Array.from(children || []).forEach((value, ind, elements) => {
      const el = value as HTMLDivElement;
      if (
        el.getAttribute('class') === ['active', 'enter'].join(' ') ||
        el.getAttribute('class') === 'initial'
      ) {
        el.setAttribute('class', 'prev');
      } else {
        el.removeAttribute('class');
      }
      (async () => {
        if (ind == props.index) {
          el.setAttribute('class', ['active'].join(' '));
          await delay(30);
          el.setAttribute('class', ['active', 'enter'].join(' '));
          checkElementScrollable(el);
        }
      })();
    });
  };

  return (
    <div className="picture-story" ref={wrapperRef}>
      {props.children}
    </div>
  );
}

export default PictureStory;
