{
  /*import React from "react";*/
}
import React, { ReactElement } from 'react';
import { useWindowHeight } from '../hooks/useWindowHeight';
import cn from 'classnames';
import '../styles/components/LogoList.scss';
import { Portfolio } from '../types/portfolio';

type ImageField = {
  urlSite: string;
  urlImg: string;
  alt: string;
};

type Props = {
  title: string;
  theme: 'partners' | 'portfolio';
  portfolio?: Portfolio[];
  partners?: ImageField[];
};

const LogoList = ({ title, theme, portfolio, partners }: Props): ReactElement => {
  const baseWidth = 768;
  const isSp = window.outerWidth < baseWidth;
  const [height] = useWindowHeight();
  const items: ImageField[] =
    (theme === 'partners'
      ? partners
      : portfolio?.map((item) => ({
          urlSite: item.acf.url,
          urlImg: item.acf.image.url,
          alt: item.title.rendered,
        }))) || [];
  return (
    <section
      className={cn('item-common', `${theme}`)}
      style={{
        height: !isSp ? `${height}px` : undefined,
        minHeight: isSp ? `${height}px` : undefined,
      }}
    >
      <div
        className="l-container"
        style={{
          minHeight: isSp ? `${height}px` : undefined,
        }}
      >
        <div className={cn('item-common__contents', `item-common__contents--${theme}`)}>
          <h2 className={cn('title', `title-${theme}`)}>{title}</h2>

          <ul className={cn('logo-list', `logo-list__${theme}`)}>
            {items.map((item, index) => (
              <Item item={item} key={index} />
            ))}
          </ul>

          <p className={cn('caption', `caption-${theme}`)}>
            ※PARTYからの
            <br className="l-sm" />
            支援を含みます
          </p>
        </div>
      </div>
    </section>
  );
};

const Item = ({ item }: { item: ImageField }): ReactElement => {
  return (
    <li className={cn('logo-list__item', { ['btn-hover']: item.urlSite !== '' })}>
      {item.urlSite === '' ? (
        <picture className="img-box">
          <img className="logo" src={item.urlImg} alt={item.alt} />
        </picture>
      ) : (
        <a className="link-box" href={item.urlSite} target="_blank" rel="noopener noreferrer">
          <picture className="img-box">
            <img className="logo" src={item.urlImg} alt={item.alt} />
          </picture>
        </a>
      )}
    </li>
  );
};

export default LogoList;
