import { Tteam } from '../types/team';
import React, { useEffect, ReactElement } from 'react';
import { VFC } from 'react';
import cn from 'classnames';
import '../styles/components/Team.scss';

type Props = {
  theme: 'left' | 'right';
  item: Tteam;
};

export const N2br: VFC<{
  children: string | undefined;
}> = ({ children }) => {
  const content: string[] = children ? children.split('\n') : [];
  return <span dangerouslySetInnerHTML={{ __html: content.join('<br/>') }} />;
};

const Team = (props: Props): ReactElement => {
  return (
    <div className={cn('team-list__item', `team-list__item--${props.theme}`)}>
      <div className="team-list__item--profile">
        {/* 名前、役職 */}
        <div className="name-box">
          <h3 className="name">
            <N2br>{props.item.name}</N2br>
          </h3>
          <p className="position">{props.item.position}</p>
        </div>

        {/* 説明 */}
        <div className="profile-box">
          <div className={cn('profile-box__position', { empty: !props.item.positionProf })}>
            <p className="text">{props.item.positionProf}</p>
          </div>

          <div className="profile-box__text">
            <p className="text">{props.item.textProf}</p>
          </div>
        </div>
      </div>

      <div className="team-list__item--img">
        <picture className="img-box">
          <img className="img" src={props.item.imgUrl} alt={props.item.name} />
        </picture>
      </div>
    </div>
  );
};

export default Team;
