import React, { ReactElement } from 'react';
import '../styles/components/News.scss';
import NewsItems from './NewsItem';
import { Information } from '../types/news';
import { useWindowHeight } from '../hooks/useWindowHeight';

type Props = {
  news: Information[];
};

function News(props: Props): ReactElement {
  const [height] = useWindowHeight();
  return (
    <section className="item-news" style={{ minHeight: `${height}px` }}>
      <div className="l-container">
        <div className="item-news__title">
          <h2 className="title">NEWS</h2>
        </div>

        <ul className="news-list">
          {props.news.map((item, index) => {
            return (
              <li className="news-list__item" key={index}>
                <NewsItems news={item} />
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
}

export default News;
