export const partners = [
  {
    urlSite: 'https://prty.jp/',
    urlImg: `${IMAGE_PATH}images/partners/img-logo-party.png`,
    alt: 'PARTY',
  },
  {
    urlSite: 'https://www.toppan.co.jp/',
    urlImg: `${IMAGE_PATH}images/partners/img-logo-toppan.png`,
    alt: 'TOPPAN',
  },
  {
    urlSite: 'https://geekpictures.co.jp/',
    urlImg: `${IMAGE_PATH}images/partners/img-logo-geek.png`,
    alt: 'GEEK PICTURES',
  },
  {
    urlSite: 'https://vectorinc.co.jp/',
    urlImg: `${IMAGE_PATH}images/partners/img-logo-vector.png`,
    alt: 'vector',
  },
  {
    urlSite: 'https://sun-asterisk.com/',
    urlImg: `${IMAGE_PATH}images/partners/img-logo-sun.png`,
    alt: 'sun',
  },
  {
    urlSite: 'https://forstartups.com/',
    urlImg: `${IMAGE_PATH}images/partners/img-logo-forstartup.png`,
    alt: 'forStartup',
  },
  {
    urlSite: 'https://www.uzabase.com/',
    urlImg: `${IMAGE_PATH}images/partners/img-logo-uzabase.png`,
    alt: 'UZABASE',
  },
  {
    urlSite: 'https://unicorn-fa.com/',
    urlImg: `${IMAGE_PATH}images/partners/img-logo-ufa.png`,
    alt: 'Unicorn Financial Advisory',
  },
];
