import { Tteam } from '../types/team';

export const team: Tteam[] = [
  {
    name: '中村洋基',
    position: '代表取締役',
    positionProf:
      'PARTY Creative Director / Founder\nヤフー株式会社 メディアカンパニーMS統括本部\nエグゼクティブクリエイティブ・ディレクター\n電通デジタル 客員エグゼクティブ\nクリエイティブ・ディレクター\n予防医療普及協会 理事',
    textProf:
      '(株)電通にて斬新なアプローチのバナー広告を次々と発表後、2011年、PARTY共同創業。クリエイティブとテクノロジーのスペシャリスト集団を標榜。国内外300以上の広告賞を受賞、電通アクセラレーター「Grasshopper」の立上げ、スタートアップ投資・支援も多数行っている。',
    imgUrl: `${IMAGE_PATH}images/team/img-team-nakamura.png`,
  },
  {
    name: '田中潤',
    position: '取締役',
    positionProf:
      'PARTY Operating Officer / Executive Producer\n株式会社 The Chain Museum 取締役COO\n株式会社 スタジアムエクスペリメント Producer\n株式会社 CYPAR  Executive Producer',
    textProf:
      '広告代理店を経て、2012年PARTYに参加。事業戦略の視点から、サービス/プロダクト開発・PR戦略・広告制作のプロデュースを得意とする。PARTYから生まれたスタートアップのCOO/CFOを兼任。グロービス経営大学院大学MBA修了。',
    imgUrl: `${IMAGE_PATH}images/team/img-team-tanaka.png`,
  },
  {
    name: '菅野\n清太郎',
    position: '社外取締役',
    positionProf: 'TOPPANホールディングス株式会社\n事業開発本部\n戦略投資部 事業共創チーム課長',
    textProf:
      'TOPPANホールディングス事業開発本部 戦略投資部 事業共創チーム課長 。営業、コンテンツコラボレーションや広告企画、美術展の企画・広報業務を担当した後、2021年より、ベンチャー投資およびM&Aを通じた事業開発を担当。',
    imgUrl: `${IMAGE_PATH}images/team/img-team-kanno.png`,
  },
  {
    name: '米本大河',
    position: '経営企画',
    textProf:
      '海外エネルギー商社の取締役として貿易取引における交渉・経営管理・投資判断に従事した後、アクセンチュアでビッグデータの抽出・分析をもとにしたITコンサルティングを担当。2017年より、5年間にわたってVR関連スタートアップのCEOとして0→1事業創造に取り組む。',
    imgUrl: `${IMAGE_PATH}images/team/img-team-yonemoto.png`,
  },
  {
    name: '橋本遥',
    position: '経営企画',
    textProf:
      'DeNAで2件の新規事業立ち上げの後、バイオテックスタートアップを経てDigital Garageで国内外スタートアップへの投資およびアクセラレーションプログラムの立ち上げ・統括。\nその後独立し、スタートアップ育成の事業を行う。また社外のピッチコンテスト等で審査員やメンターを務める。\n\n\n2023年3月よりcomboに参画。\n京都大学大学院 農学研究科 応用生命科学専攻 修士課程修了',
    imgUrl: `${IMAGE_PATH}images/team/img-team-hashimoto.png`,
  },
];
