import React, { useEffect, useState, useRef } from 'react';
import { useWindowHeight } from '../hooks/useWindowHeight';
import '../styles/components/FirstView.scss';
import cn from 'classnames';

type Props = {
  textMode?: boolean;
  index: number;
};

const FirstView = (props: Props): React.ReactElement => {
  const [videoEnd, setVideoEnd] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const spVideoRef = useRef<HTMLVideoElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const baseWidth = 768;
  const isSp = window.outerWidth < baseWidth;
  const [height] = useWindowHeight();

  useEffect(() => {
    videoRef.current?.addEventListener('play', (e) => {
      //資料DLボタンを表示
      setTimeout(() => {
        setVideoEnd(true);
      }, 8 * 1000);
    });
    //ビデオを指定時間でループ
    videoRef.current?.addEventListener('ended', (e) => {
      if (videoRef.current) {
        videoRef.current.currentTime = 12.1;
        videoRef.current.play();
      }
    });
    spVideoRef.current?.addEventListener('play', (e) => {
      //資料DLボタンを表示
      setTimeout(() => {
        setVideoEnd(true);
      }, 8 * 1000);
    });
    //ビデオを指定時間でループ
    spVideoRef.current?.addEventListener('ended', (e) => {
      if (spVideoRef.current) {
        spVideoRef.current.currentTime = 12.4;
        spVideoRef.current.play();
      }
    });
  }, [videoRef, spVideoRef]);

  return (
    <section className="item-firstview" style={{ height: `${height}px` }}>
      {!isSp && (
        <>
          {props.textMode ? (
            <img
              src={`${IMAGE_PATH}images/video.png`}
              style={{ display: isSp ? 'none' : 'inline-block' }}
            />
          ) : (
            <video
              ref={videoRef}
              src={`${IMAGE_PATH}images/combo_web_v2.mp4`}
              muted={true}
              autoPlay={true}
            ></video>
          )}
        </>
      )}
      {isSp && (
        <>
          {props.textMode ? (
            <img ref={imgRef} src={`${IMAGE_PATH}images/video-sp.png`} className="video-sp_img" />
          ) : (
            <video
              className="sp_video"
              ref={spVideoRef}
              src={`${IMAGE_PATH}images/combo_web_sp_v2.mp4`}
              muted={true}
              autoPlay={true}
              playsInline={true}
              style={{ top: 0, height: `${window.innerHeight + 1}px` }}
            ></video>
          )}
        </>
      )}
      {!isSp && (
        <div className="item-firstview__lines" style={{ zIndex: 0 }}>
          <div>
            <div></div>
          </div>
        </div>
      )}
      <div className={cn('l-container')}>
        <div className="item-firstview__title">
          <ul className={cn('option-link', { enable: videoEnd || props.textMode })}>
            <a
              href={`${IMAGE_PATH}images/combo_about.pdf`}
              target="_blank"
              rel="noreferrer"
              download="combo_about.pdf"
            >
              <li className={cn('option-link__item', 'btn-hover', props.index === 0 && 'show')}>
                資料ダウンロード
              </li>
            </a>
          </ul>
        </div>
        <div className={cn('item-firstview__item', props.index === 1 && 'show')}>
          {!isSp && (
            <div className="text-box">
              <p className="text-box__text">
                <span className="YuGothic">combo</span>
                は、
                <br />
                <span className="YuGothic">PARTY</span>から生まれた、
                <br />
                新規事業を創出し、
                <br />
                成長させるための会社です。
              </p>
            </div>
          )}

          {isSp && (
            <div className="text-box">
              <p className="text-box__text">
                <span className="YuGothic">combo</span>
                は、
                <br />
                <span className="YuGothic">PARTY</span>
                から生まれた、
                <br />
                新規事業を創出し、
                <br />
                成長させるための会社です。
              </p>

              <p className="text-box__text">
                「スタートアップスタジオ事業」と
                <br />
                「アクセラレーション事業」の
                <br />
                <span className="space">2つの機能があります。</span>
              </p>
            </div>
          )}
        </div>
      </div>

      <div className={cn('scroll-box', props.index === 0 && 'show')}>
        <p className="scroll-box__text">
          SCROLL
          <span className="bar"></span>
        </p>
      </div>
    </section>
  );
};

export default FirstView;
